import { Scene } from "scene-components/scene";
import { Stats } from "@react-three/drei";
import WebGPURenderer from "three/examples/jsm/renderers/webgpu/WebGPURenderer";
import { render, events, unmountComponentAtNode } from "@react-three/fiber";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import useMeasure from "react-use-measure";
import mergeRefs from "react-merge-refs";
import { Props } from "@react-three/fiber/dist/declarations/src/web/Canvas";

export function HomePage() {
  return (
    <WebGPUCanvas>
      <Scene />
      <Stats />
    </WebGPUCanvas>
  );
}

function WebGPUCanvas({ children, resize, style, className, ...props }: Props) {
  const [bind, size] = useMeasure({
    scroll: true,
    debounce: { scroll: 50, resize: 0 },
    ...resize,
  });
  const ref = useRef<HTMLDivElement>(null!);
  const [gl, setGL] = useState<WebGPURenderer>();

  useEffect(() => {
    const webGPURenderer = new WebGPURenderer();
    webGPURenderer.init().then(() => {
      setGL(webGPURenderer);
    });
  }, []);

  useLayoutEffect(() => {
    console.log(size);
    if (size.width > 0 && size.height > 0)
      render(children, ref.current, {
        ...props,
        size,
        events: events as any,
        gl,
      });
  }, [gl, size, children]);

  useEffect(() => {
    if (gl) {
      const container = ref.current;
      container.appendChild(gl.domElement);
      return () => {
        container.removeChild(gl.domElement);
        unmountComponentAtNode(container);
      };
    }
  }, [gl]);

  if (!gl) {
    return (
      <span>WebGPU is not supported. Make sure to run the latest Browser.</span>
    );
  }

  return (
    <div
      ref={mergeRefs([ref, bind])}
      className={className}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        ...style,
      }}
    />
  );
}

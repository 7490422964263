import { useThree } from "@react-three/fiber";
import React, { useEffect } from "react";
import { Physics } from "use-ammojs";
import { Ground } from "scene-components/ground";
import { BasicLighting } from "entities/scene/basic-lighting";
import { OrbitControls } from "@react-three/drei";
import { Vector3 } from "three";
import { ComputePoints } from "./compute-points/compute-points";

const SUN_POSITION = new Vector3(2, 4, 5);

export function Scene() {
  const { camera } = useThree();

  useEffect(() => {
    camera.far = 4000;
    camera.position.y = 5;
    camera.position.x = 0;
    camera.position.z = 30;
    camera.updateProjectionMatrix();
  }, [camera]);

  return (
    <Physics drawDebug={false} fixedTimeStep={1 / 120}>
      <BasicLighting />
      <Ground />
      <OrbitControls />

      <ComputePoints />

      {/*<Crane />*/}

      {/*<Sky*/}
      {/*  sunPosition={SUN_POSITION}*/}
      {/*  inclination={0}*/}
      {/*  azimuth={0.25}*/}
      {/*  distance={4000}*/}
      {/*/>*/}
    </Physics>
  );
}
